import { useState, useEffect, useCallback, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import ContentAdd from "@mui/icons-material/Add";
import {
  ReferenceInput,
  List,
  Datagrid,
  TextField,
  FunctionField,
  SearchInput,
  TextInput,
  useListContext,
  useRecordContext,
  SelectInput as RaSelectInput,
  TopToolbar,
  FilterButton,
  ExportButton,
  CreateButton,
  Button,
  useDataProvider,
  useRedirect,
  CloneButton,
  DateInput,
  AutocompleteInput,
  SelectArrayInput,
  ReferenceField,
  ReferenceArrayInput,
  SelectColumnsButton,
  BulkDeleteWithConfirmButton,
  BulkExportButton,
  Empty,
  defaultExporter,
  useListController,
} from "react-admin";
import {
  caseStatusEnum,
  defaultfilterCaseStatus,
  DateField,
  DateField2,
  AssigneeField,
  PreparerField,
  CaseStatusField,
  QuickFilter,
} from "../erpd";

import CommonDataGrid from "../components/CommonDataGrid";
import { customExporter } from "../utils/customExporter";

const caseFromToEnum = [
  { id: "My", name: "By/To Me" },
  { id: "By Me", name: "By Me" },
  { id: "To Me", name: "To Me" },
  { id: "To My Department", name: "To My Department" },
  { id: "To Everyone", name: "To Everyone" },
  { id: "Other", name: "Other" }, // todo: remove this for non-admin
];

function SelectInput(props) {
  return (
    <RaSelectInput
      sx={{
        ".MuiFilledInput-root": {
          borderRadius: 2,
          border: "1px solid ",
        },
        ".MuiFilledInput-root::before": {
          borderWidth: 0,
        },
        ".MuiFilledInput-root:hover::before": {
          borderWidth: 0,
        },
        ".MuiFilledInput-root::after": {
          borderWidth: 0,
        },
        ".MuiFilledInput-root:hover::after": {
          borderWidth: 0,
        },
      }}
      {...props}
    />
  );
}

const ListActions = ({ project }) => {
  const createCaseButton = project ? (
    <CloneButton
      variant="contained"
      resource="cases"
      label="CREATE"
      record={{ project_id: project.id }}
      icon={<ContentAdd />}
      sx={{ marginLeft: 2 }}
    />
  ) : null;
  return (
    <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      <ExportButton />
      {createCaseButton}
    </TopToolbar>
  );
};

// const BulkActionButtons = (props) => {
//   let provider;
//   let deletionProgress; // an array of switches to mark which elements have been deleted

//   deletionProgress = new Array(props.selectedIds.length).fill(-1);
//   provider = useDataProvider();

//   return (
//     <Fragment>
//       <Button
//         label="Delete cases"
//         onClick={() => {
//           props.selectedIds.map((selectedId, i) => {
//             provider
//               ._cases_deleteCase({
//                 projectId: props.record.id,
//                 caseId: selectedId,
//               })
//               .then((data) => {
//                 //expected error
//                 if (data.error)
//                   return console.log("deletecase error : ", data.error);
//                 else console.log("delete success");
//                 deletionProgress[i] = 1;
//                 //check for deletion progress
//                 if (!deletionProgress.includes(-1))
//                   props.setDeleteComplete(true);
//               })
//               .catch((e) => {
//                 //unexpected error
//                 console.log("unexpected error deletcase ", e);
//               });
//           });
//         }}
//       />
//     </Fragment>
//   );
// };

function DocField() {
  const case_ = useRecordContext();
  if (case_.drawing_id) {
    return <ReferenceField reference="drawings" source="drawing_id" link={false} />;
  }
  if (case_.minute_id) {
    return <ReferenceField reference="minutes" source="minute_id" link={false} />;
  }
  if (case_.document_id) {
    return <ReferenceField reference="drawings" source="document_id" link={false} />;
  }
  return null;
};

function showCaseOrPdf(id, resource, record) {
  const filter = "filter=" + encodeURIComponent(`{"no": ${record.no}}`) + `&aid=${record.annotations?.at(0)?._id}&case=${id}`;
  if (record.drawing_id) {
    return `/drawings/${record.drawing_id}/show?${filter}`;
  }
  if (record.minute_id) {
    return `/minutes/${record.minute_id}/show?${filter}`;
  }
  return `/cases/${id}/show`;
}

const CaseTable = (props) => {
  const showProject = props.showProject || false;
  // let list = useListContext();
  // let redirect = useRedirect();
  // const project = useRecordContext();

  return (
    <CommonDataGrid
      bulkActionButtons={
        <>
          <BulkExportButton />
          <BulkDeleteWithConfirmButton />
        </>
      }
      omit={["createdAt"]}
      rowClick={showCaseOrPdf}
    >
      <TextField source="no" label="No." />
      {showProject && <TextField source="project.name" label="Project" />}
      {/* <TextField source="case_type" label="Type" /> */}
      {/* <ReferenceField source="case_type" reference="case_types">
        <TextField source="name" label="Case Type" />
      </ReferenceField> */}
      <ReferenceField
        label="Case Type"
        reference="dropdown_names"
        source="case_type_id"
        link={false}
      />
      {/* <TextField source="caseType.name" label="Case Type" /> */}
      <TextField source="subject" />
      <DocField label="PDF" />
      {/* preparer (role_Id) is equal to createdBy (user_id) */}
      <PreparerField />
      <AssigneeField />
      <TextField source="num_comments" label="Comments" />
      {/* <TextField source="status" /> */}
      <CaseStatusField />
      {/* <FunctionField source="status" render={record => {
        return <span
          style={{
            color:
              record.status === 'Open' ? '#2972FF' :
                record.status === 'Completed' ? '#1B9982' :
                  '#DE1515'
          }}
        >{record.status}</span>
      }} /> */}
      <DateField source="due_date" label="Due Date" showTime={false} />
      <DateField source="createdAt" label="Created At" />
      <DateField source="updatedAt" label="Updated At" />
    </CommonDataGrid>
  );
};

// const EmptyList = () => (
//   <Box textAlign="center" m={1}>
//     <Typography variant="h4" paragraph>
//       No products available
//     </Typography>
//     <Typography variant="body1">Create one or import from a file</Typography>
//     <CreateButton variant="contained" />
//     <Button onClick={() => { }}>Import</Button>
//   </Box>
// );

// Define constants for custom export
// Header name should follow according to the grid title
const headerTemplate = "ID,Subject,Reference,Preparer,Assignee,Comments,Status,Due Date,Created At";

// Parse in the record arrangement as the display
// rowTemplate for CSV
const rowTemplate = (record) => {
  return `${record.no},${record.subject},${record.drawing_id ? 'Drawing' : record.minute_id ? 'Minute' : 'Case'},${record.createdBy.name},${record.assignedTo},${record.num_comments || ''},${record.status},${record.due_date || ''},${record.createdAt}`;
};


// 2 types of case list: of single project (default filter project, can create case), of all projects (can filter project)
export const CaseList = (props) => {
  const project = useRecordContext(); // project is null when list all projects
  const showProject = project == null;
  // if (!project || !project.id) return null;

  // const { resource } = useListController(props);
  
  const filters = [
    <SearchInput source="no" placeholder="No." alwaysOn style={{ width: "100px" }} />,
    <SearchInput
      source="subject"
      placeholder="Subject"
      style={{ width: "200px" }}
      alwaysOn
    />,
    <SelectInput
      label="From/To"
      source="fromto"
      choices={caseFromToEnum}
      emptyText="Show All"
    />,
    <QuickFilter
      source="ongoing"
      label="Ongoing"
      defaultValue={true}
    />,
    <SelectInput
      source="status"
      choices={caseStatusEnum}
      emptyText="Show All"
      alwaysOn
    />,
    <ReferenceInput
      label="Preparer"
      source="preparer"
      reference="members"
      filter={{ project_id: project?.id }}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionValue="id" optionText="name" emptyText="Show All"/>
    </ReferenceInput>,
    <ReferenceInput
      label="Assignee"
      source="assignee"
      reference="assignees"
      filter={{ project_id: project?.id }}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionValue="id" optionText="name" emptyText="Show All" />
    </ReferenceInput>,
    showProject ? null : (
      <ReferenceInput
        source="case_type_id"
        reference="case_types"
        filter={{ project_id: project?.id }}
      />
    ),
    // (showProject? <ReferenceInput source="project_id" reference="projects" alwaysOn /> : null),
    // <DateInput source="dued_since" />,
    // <DateInput source="dued_before" />,
    // <DateInput source="updatedAt:>=" label="Updated Since" />,
    // <DateInput source="updatedAt:<" label="Updated Before" />,
    // <DateInput source="createdAt:>=" label="Created Since" />,
    // <DateInput source="createdAt:<" label="Created Beofre" />,
    // <ReferenceInput source="createdBy" reference="users" />,
    // <ReferenceArrayInput source="assignee" reference="assignees" filter={{project_id: project?.id}} />,
    // <TextInput source="case_type" />,
  ].filter((x) => x !== null);

  return (
    <List
      {...props}
      // disableSyncWithLocation
      resource={showProject? "pcases":"cases"}
      filters={filters}
      // filterDefaultValues={{ status: defaultfilterCaseStatus }}
      filter={showProject ? {} : { project_id: project?.id }}
      sort={{ field: "updatedAt", order: "DESC" }}
      // perPage={21}
      actions={<ListActions project={project} />}
      // empty={<Empty hasCreate={false}/>}
      empty={false}
      exporter={(records, fetchRelatedRecords) =>
        customExporter(records, fetchRelatedRecords, headerTemplate, rowTemplate, "pcases")
      }
      storeKey={`case_list_${project?.id}`}
    >
      <CaseTable showProject={showProject} />
    </List>
  );
};
